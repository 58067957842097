// This function conditionally loads the Global SEO translation engine based on a feature flag.
// Add the class 'globalseo-exclude' to any element that should not be translated.
// This function is called in the main Vue instance in the main.ts file.
// https://globalseo.ai/

export const loadGlobalSEO = () => {
  const globalseoKey = import.meta.env.VITE_GLOBALSEO_KEY;
  const allowedLanguages = import.meta.env.VITE_GLOBALSEO_ALLOWED_LANGUAGES;

  if (globalseoKey && allowedLanguages) {
    console.log('Global SEO translation engine is loading...');

    try {
      const link = document.createElement('link');
      link.href = 'https://unpkg.com/globalseo/dist/translate.css';
      link.rel = 'stylesheet';
      document.head.appendChild(link);

      console.log('Global SEO translation engine CSS is loaded.');

      const script = document.createElement('script');
      script.src = 'https://unpkg.com/globalseo/dist/translate.js';
      script.type = 'text/javascript';
      script.setAttribute('data-globalseo-key', globalseoKey);
      script.setAttribute('data-use-browser-language', 'true');
      script.setAttribute('data-original-language', 'en');
      script.setAttribute('data-translation-mode', 'searchParams');
      script.setAttribute('data-translate-form-placeholder', 'true');
      script.setAttribute('data-translate-select-options', 'true');
      script.setAttribute('data-allowed-languages', allowedLanguages);
      script.setAttribute('data-exclude-classes', '');
      document.head.appendChild(script);

      console.log('Global SEO translation engine JS is loaded.');
    } catch (error) {
      console.error('Error loading Global SEO translation engine:', error);
    }
  }
};
