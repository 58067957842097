export enum AssetContainerEventType {
  Arrived = 'Arrived',
  Loading = 'Loading',
  LoadingComplete = 'Loading Complete',
  Unloading = 'Unloading',
  UnloadingComplete = 'Unloading Complete',
  ReadyForPickup = 'Ready for Pickup',
  Departed = 'Departed'
}

export interface IAssetContainerEvent {
  id: string;
  createDateTime: Date;
  createdBy?: string | null;
  orgId: string;
  assetContainerId: string;
  eventType: AssetContainerEventType;
}
