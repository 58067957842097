<template>
  <div
    class="pa-4 fill-height d-flex flex-column fill-height"
    :class="{ 'justify-space-between': !hasAppointmentData }">
    <loading-overlay v-if="loading" :message="loadingMessage" show></loading-overlay>
    <location-permission-dialog
      :visible="askingForLocation"
      @agree="retrieveLocation()"
      @disagree="onNoGpsAllowed()"></location-permission-dialog>

    <warehouse-header
      class="globalseo-exclude"
      primary
      :warehouse="warehouseData"></warehouse-header>
    <div v-if="!hasAppointmentData && !isUnplanned">
      <h1 class="title">Let's check in</h1>
      <p class="instructions">
        Please type in your {{ appointmentIdentifierLabel }} to locate your appointment
      </p>
      <v-text-field
        v-model="appointmentIdentifier"
        color="primary"
        :label="appointmentIdentifierLabel"
        @focus="scrollIntoView"
        variant="underlined"></v-text-field>
      <v-btn color="primary" @click="fetchAppointmentData()" block>locate appointment</v-btn>
      <v-btn variant="outlined" class="mt-4" block @click="handleUnplannedCheckinClick">
        I don't have an appointment
      </v-btn>
    </div>

    <div class="d-flex full-width align-center justify-center">
      <language-selector />
    </div>

    <appointment-form
      v-if="shouldRenderAppointmentForm"
      :appointment="appointmentData"
      :latitude="String(latitude)"
      :longitude="String(longitude)"
      :warehouse="warehouseData"
      @back="
        appointmentData = {} as IAppointment;
        appointmentIdentifier = '';
      "></appointment-form>

    <unplanned-arrival-form
      v-if="isUnplanned"
      :warehouse="warehouseData"
      :latitude="String(latitude)"
      :longitude="String(longitude)"
      @on-error="trackUnplannedError"
      @on-success="trackUnplannedSuccess"
      @back="
        isUnplanned = false;
        appointmentIdentifier = '';
      "></unplanned-arrival-form>
    <learn-more-footer v-if="!hasAppointmentData && !isUnplanned"></learn-more-footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import {
  clearLocalStorageKey,
  updateObjectInLocalStorage
} from '@/components/mixins/localStorageMixin';
import type { IWarehouse, IAppointment } from '@nova/core';

import retrieveLocationMixin from '@/components/mixins/retrieveLocationMixin';
import UnplannedArrivalForm from '@/components/UnplannedArrivalForm.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';

export default defineComponent({
  components: { UnplannedArrivalForm, LanguageSelector },
  mixins: [retrieveLocationMixin],
  async mounted() {
    clearLocalStorageKey('comet:appointment');
    clearLocalStorageKey('comet:warehouse');
    clearLocalStorageKey('comet:isPlanned');
    this.verifyWarehouse();

    if (this.warehouseId) {
      await this.setWarehouse();
      if (this.warehouseData?.id) {
        if (this.warehouseData.settings?.gateManagementRequiresGeofencing) {
          await this.askForLocation();
        } else {
          this.onLocationSuccess();
        }

        // Set on global store
        if (this.warehouseData.settings?.enableMilitaryTime === true) {
          this.$store.dispatch('setMilitaryTime', true);
        }
      }
    }
  },
  data() {
    return {
      submitting: false,
      retrievingWarehouse: false,
      appointmentIdentifier: '',
      warehouseId: '',
      appointmentData: {} as IAppointment,
      warehouseData: {} as IWarehouse,
      isUnplanned: false
    };
  },
  computed: {
    shouldRenderAppointmentForm() {
      return !this.isUnplanned && this.hasAppointmentData;
    },
    appointmentIdentifierLabel() {
      const referenceDisplayName =
        this.warehouseData.settings?.referenceNumberDisplayName ?? 'PO Number';

      return `${referenceDisplayName} or Confirmation Number`;
    },
    hasAppointmentData() {
      return this.appointmentData?.id;
    },
    loading() {
      return this.calculatingLocation || this.submitting || this.retrievingWarehouse;
    },
    loadingMessage() {
      if (this.calculatingLocation) {
        return 'Retrieving location...';
      } else if (this.submitting) {
        return 'Submitting...';
      } else if (this.retrievingWarehouse) {
        return 'Retrieving warehouse data...';
      }
      return '';
    }
  },
  methods: {
    scrollIntoView($e: FocusEvent) {
      const targetElement = $e.target as Element;
      targetElement?.scrollIntoView({ behavior: 'smooth' });
    },
    handleUnplannedCheckinClick() {
      this.isUnplanned = true;
      this.mixpanel.track("Self Check-in: I Don't Have an Appointment", {
        'Warehouse ID': this.warehouseId,
        'Warehouse Name': this.warehouseData?.name,
        'Org ID': this.warehouseData?.orgId,
        'Org Name': this.warehouseData?.org?.name
      });
    },
    verifyWarehouse() {
      this.warehouseId = (this.$route.query.warehouseId as string) ?? '';

      if (!this.warehouseId) {
        return this.$router.push({
          name: 'warehouse-error',
          params: {
            icon: 'mdi-alert',
            title: 'Missing warehouse',
            message: 'The link is invalid, it must contain the warehouse Id'
          }
        });
      }
    },
    async onLocationSuccess() {
      this.trackMixpanelStarted();
    },
    async setWarehouse() {
      try {
        this.retrievingWarehouse = true;
        const { data } = await this.axiosClient.get(`/checkin/public/${this.warehouseId}/`);

        if (data?.data?.id) {
          this.warehouseData = { ...data.data };
          updateObjectInLocalStorage('comet:warehouse', this.warehouseData);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorParams = this.makeErrorScreenParams(err);

          this.trackMixpanelError(errorParams);

          this.$router.push({
            name: 'warehouse-error',
            params: errorParams
          });
        }
      } finally {
        this.retrievingWarehouse = false;
      }
    },
    trackMixpanelStarted() {
      this.mixpanel.track('Started: Self Check In', {
        'Warehouse ID': this.warehouseId || 'N/A',
        'Warehouse Name': this.warehouseData?.name || 'N/A',
        'Org ID': this.warehouseData?.orgId,
        'Org Name': this.warehouseData?.org?.name,
        Latitude: this.latitude || 'N/A',
        Longitude: this.longitude || 'N/A'
      });
    },
    trackMixpanelError(errParams: Record<string, unknown>) {
      this.mixpanel.track('Error: Self Check In', {
        'Appointment Validation #': this.appointmentIdentifier || 'N/A',
        'Warehouse ID': this.warehouseId || 'N/A',
        'Warehouse Name': this.warehouseData?.name || 'N/A',
        'Org ID': this.warehouseData?.orgId,
        'Org Name': this.warehouseData?.org?.name,
        Latitude: this.latitude || 'N/A',
        Longitude: this.longitude || 'N/A',
        'Error Type': errParams.title
      });
    },
    trackUnplannedError(errParams: Record<string, unknown>) {
      this.mixpanel.track('Error: Self Check In', {
        'Check-in Type': 'Unplanned Arrival',
        'Warehouse ID': this.warehouseId,
        'Warehouse Name': this.warehouseData?.name,
        'Org ID': this.warehouseData?.orgId,
        'Org Name': this.warehouseData?.org?.name,
        'Error Type': errParams.title
      });
    },
    trackUnplannedSuccess(data: Record<string, unknown>) {
      this.mixpanel.track('Completed: Self Check-in', {
        'Asset ID': data?.id || 'N/A',
        'Warehouse ID': this.warehouseId,
        'Warehouse Name': this.warehouseData?.name,
        'Org ID': this.warehouseData?.orgId,
        'Org Name': this.warehouseData?.org?.name,
        'Changed By': 'Self Check-in User',
        'Entry Point': 'Self Check-in',
        'Check-in Type': 'Unplanned Arrival'
      });
    },
    async fetchAppointmentData() {
      if (!this.appointmentIdentifier) {
        return;
      }

      try {
        this.submitting = true;
        const { data } = await this.axiosClient.get(
          `/checkin/public/${encodeURIComponent(this.appointmentIdentifier)}/${
            this.warehouseId
          }/?latitude=${this.latitude}&longitude=${this.longitude}`
        );

        if (data?.data?.id) {
          this.appointmentData = { ...data.data };

          this.mixpanel.track('Self Check-in: Appointment Located', {
            'Warehouse ID': this.warehouseId,
            'Warehouse Name': this.warehouseData?.name,
            'Appointment Validation #':
              this.appointmentData.refNumber || this.appointmentData.confirmationNumber,
            'Appointment ID': this.appointmentData.id,
            'Appointment Start': this.appointmentData.start,
            'Org ID': this.warehouseData?.orgId,
            'Org Name': this.warehouseData?.org?.name
          });
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorParams = this.makeErrorScreenParams(err);

          this.trackMixpanelError(errorParams);

          this.$router.push({
            name: 'appointment-error',
            params: errorParams
          });
        }
      } finally {
        this.submitting = false;
      }
    }
  }
});
</script>

<style scoped>
.title {
  font-family: Poppins, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  color: #1e3036;
}

.instructions {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #1e3036;
}
</style>
