<template>
  <v-btn loading block v-if="loading">Uploading...</v-btn>
  <v-file-input
    v-else
    ref="fileInput"
    :disabled="loading"
    :multiple="multiple"
    :placeholder="placeholder"
    v-model="selectedFiles"
    @change="onFileChanged"
    @click:clear="notifyClearFile"
    :error-messages="errorMessages"
    :label="label"
    hide-details="auto"
    :required="required"></v-file-input>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {
    appointmentIdentifier: {
      type: String,
      required: false
    },
    warehouseId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'File uploader'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    maxSize: {
      type: Number,
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      renderKey: 0,
      mounted: false,
      selectedFiles: [],
      isSelectingFile: false,
      fileUrls: [],
      fileKeys: [],
      tempFileBase64: '',
      tempFileSize: 0,
      errorMessages: [],
      loading: false
    };
  },
  methods: {
    isFileSizeValid(file) {
      if (!this.maxSize) {
        return true;
      }

      if (!file) {
        this.notify('File is not valid', 'error');
        return false;
      }

      const sizeInUnit = this.novaCore.convertBytes(file.size, this.conversionUnit);

      if (sizeInUnit > this.maxSize) {
        const errorMessage = `File size exceeds limit of ${this.maxSize}${this.conversionUnit}`;
        this.errorMessages.push(errorMessage);
        this.notify(errorMessage, 'error');
        return false;
      }

      return true;
    },
    notifyClearFile() {
      this.fileUrls = [];
      this.fileKeys = [];
      this.errorMessages = [];

      this.$emit('cleared');
    },
    async uploadFile() {
      const remainingSlots =
        this.novaCore.MULTI_DOC_MAX_FILES_PER_APPOINTMENT - this.selectedFiles.length;

      if (this.selectedFiles.length > remainingSlots) {
        this.notify(
          `Maximum of ${this.novaCore.MULTI_DOC_MAX_FILES_PER_APPOINTMENT} files allowed`,
          'error'
        );

        return;
      }

      for (const file of this.selectedFiles) {
        if (!this.isFileSizeValid(file)) {
          return;
        }
      }

      this.isSelectingFile = true;
      this.loading = true;

      for (const file of this.selectedFiles) {
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
          const url = this.appointmentIdentifier
            ? `checkin/public/storage/${this.appointmentIdentifier}/${this.warehouseId}`
            : `checkin/public/storage/${this.warehouseId}`;
          await this.axiosClient
            .post(url, formData)
            .then(response => {
              if (response && response.data) {
                this.fileUrls.push(response.data.url);
                this.fileKeys.push(response.data.key);
                this.errorMessages = [];
              }
            })
            .catch(err => {
              console.error(
                JSON.stringify(
                  {
                    message: err?.message ?? JSON.stringify(err, null, 2),
                    code: err?.code ?? '',
                    status: err?.response?.status ?? ''
                  },
                  null,
                  2
                )
              );
              if (err?.response?.data?.message) {
                this.errorMessages = [err.response.data.message];
              } else if (err?.message) {
                this.errorMessages = [err.message];
              } else {
                this.errorMessages = [
                  'Failed to upload one or more files, please refresh the page and try again'
                ];
              }
            })
            .finally(() => {
              this.isSelectingFile = false;
              this.loading = false;
            });
        } else {
          this.fileUrls = [];
          this.fileKeys = [];
        }
      }

      this.$emit('uploaded', this.multiple ? this.fileKeys : this.fileKeys[0]);

      this.clearDataProps();
    },

    async onFileChanged() {
      await this.uploadFile();
    },
    clearDataProps() {
      this.isSelectingFile = false;
      this.tempFileBase64 = null;
      this.tempFileSize = 0;
      this.loading = false;
    }
  },
  mounted() {
    this.mounted = true;
  }
};
</script>
