// @ts-strict-ignore
import { FindOneOptions } from 'typeorm';
import * as _ from 'lodash';

export type NovaId = string;

// This is what a defaultUUID looks like.  It can be used when we want to compare against a
// UUID but not find a match in the system.
export const defaultUUID = '00000000-0000-0000-0000-000000000000';

export const defaultQueryLimit = 10000;

export interface IHasNovaId {
  id: NovaId;
}

export interface INovaEntity extends IHasNovaId {
  createDateTime: Date;
  createdBy?: string | null;
  lastChangedDateTime: Date;
  lastChangedBy?: string | null;
  isActive: boolean;
  tags: string[] | null;
}

export interface INovaWithUserId extends INovaEntity {
  userId: string;
}

export type Slim<T extends INovaEntity> = Omit<T, keyof INovaEntity>;

export class XNovaEntity implements INovaEntity {
  id: string;
  createDateTime: Date;
  createdBy: string;
  lastChangedDateTime: Date;
  lastChangedBy: string;
  isActive: boolean;
  tags: string[] | null;
}

// To be used for any object that has a "schema" that will
// change over time. Each change should be a new version.
//
// Soft requirement: the version shall be an integer between
// 1 and infinity.
export interface IVersioned {
  version: number;
}

export enum DatabaseOperations {
  C = 'Create',
  R = 'Read',
  U = 'Update',
  D = 'Delete'
}

export enum DatabaseEventType {
  AfterLoad = 'AfterLoad',
  BeforeInsert = 'BeforeInsert',
  AfterInsert = 'AfterInsert',
  BeforeUpdate = 'BeforeUpdate',
  AfterUpdate = 'AfterUpdate',
  BeforeRemove = 'BeforeRemove',
  AfterRemove = 'AfterRemove'
}

export enum DatabaseEntities {
  Appointment = 'Appointment',
  AssetContainer = 'AssetContainer',
  AssetContainerEvent = 'AssetContainerEvent',
  AssetVisit = 'AssetVisit',
  AssetVisitEvent = 'AssetVisitEvent',
  Company = 'Company',
  Dock = 'Dock',
  LoadType = 'LoadType',
  OrgCarrierSettings = 'OrgCarrierSettings',
  Org = 'Org',
  User = 'User',
  Warehouse = 'Warehouse',
  WarehouseGroup = 'WarehouseGroup',
  NotificationConfig = 'NotificationConfig',
  MessageThread = 'MessageThread',
  MessageThreadMessage = 'MessageThreadMessage',
  MessageThreadEvent = 'MessageThreadEvent',
  // Custom Forms Entities
  Form = 'Form',
  Field = 'Field',
  Flow = 'Flow',
  CustomFormData = 'CustomFormData',
  FormField = 'FormField',
  Trigger = 'Trigger',
  // Yard Entities
  Spot = 'Spot',
  SpotArea = 'SpotArea',
  SpotAssignment = 'SpotAssignment'
}

export function hasOnlyTags(appt: Partial<INovaEntity>): boolean {
  // The orgId and lastChangedBy are set by the crud guard
  const allowedKeys = ['tags', 'lastChangedBy', 'orgId'];
  const keys = appt && Object.keys(appt);
  return Boolean(
    appt &&
      Array.isArray(appt.tags) &&
      keys.length === 3 &&
      allowedKeys.every(c => keys.includes(c))
  );
}

export function findOneOptionsHaveOnlyId(options: FindOneOptions) {
  const optionsKeys = _.keys(options);
  if (optionsKeys.length === 1 && optionsKeys.includes('where')) {
    const whereKeys = _.keys(options.where);
    return whereKeys.length === 1 && whereKeys.includes('id');
  }
  return false;
}

export const databaseActions = {
  update: { pastTense: 'updated', displayName: 'Updated' },
  create: { pastTense: 'created', displayName: 'Created' },
  delete: { pastTense: 'deleted', displayName: 'Deleted' }
};
